import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../../utils/baseUrl";

export const getDesignerNames = createAsyncThunk(
  "getDesignerNames",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/designer/name`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const designerSlice = createSlice({
  name: "designer",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getDesignerNames.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDesignerNames.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getDesignerNames.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default designerSlice.reducer;
