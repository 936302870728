import styled from "styled-components";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  .mobile-message {
    padding: 16px;
    text-align: center;
    font-size: 18px;
    width: 100%;
    > .icon {
      margin-top: 20px;
      > i {
        font-size: 32px;
        color: #333;
        margin: 0 10px;
      }
    }
  }
`;

export default Wrapper;
