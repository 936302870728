import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../../utils/baseUrl";
import instance from "../../utils/axiosInstance";

// Fetch All Products
export const fetchAllProducts = createAsyncThunk(
  "fetchAllProducts",
  async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/product`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

// Fetch Single Product
export const fetchSingleProduct = createAsyncThunk(
  "fetchSingleProduct",
  async (id) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/product/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

// Fetch Products By Category
export const fetchProductsByCategory = createAsyncThunk(
  "fetchProductsByCategory",
  async (categoryName) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/product/category/${categoryName}`
      );
      return data;
    } catch (error) {
      console.log(error);
      // You might want to handle errors more explicitly, such as setting an error message in the state
      throw error;
    }
  }
);

// PATCH:  Reviews And Ratings (Rate a product)
export const ratingsAndReviews = createAsyncThunk(
  "ratingsAndReviews",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await instance.patch("/product/add-rating", body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// POST: Get Reviews And Ratings
export const getRatingsAndReviews = createAsyncThunk(
  "getRatingsAndReviews",
  async ({ productId }, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(`/product/rating`, { productId });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// GET: Delete Ratings and reviews
export const deleteRatingAndReview = createAsyncThunk(
  "deleteRatingAndReview",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await instance.delete(`/product/rating`, { data: body });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Search Products
export const searchProduct = createAsyncThunk(
  "searchProduct",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/product/search`, body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    isLoading: false,
    products: null,
    errMsg: null,
    singleProduct: null,
    userRatingsAndReviews: null,
    isRatingDeleted: false,
    searchTerm: "",
  },
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProducts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(fetchAllProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.products = null;
      state.errMsg = action.payload;
    });

    builder.addCase(fetchSingleProduct.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSingleProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleProduct = action.payload;
    });
    builder.addCase(fetchSingleProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    });

    builder.addCase(fetchProductsByCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProductsByCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload.data;
    });
    builder.addCase(fetchProductsByCategory.rejected, (state, action) => {
      state.isLoading = false;
      state.products = null;
      state.errMsg = action.error.message;
    });

    // getRatingsAndReviews
    builder.addCase(getRatingsAndReviews.pending, (state, action) => {
      state.isLoading = true;
      state.userRatingsAndReviews = null;
    });
    builder.addCase(getRatingsAndReviews.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userRatingsAndReviews = action.payload;
    });
    builder.addCase(getRatingsAndReviews.rejected, (state, action) => {
      state.isLoading = false;
      state.userRatingsAndReviews = action.payload;
    });

    // ratingsAndReviews
    builder.addCase(ratingsAndReviews.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ratingsAndReviews.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userRatingsAndReviews = action.payload;
    });
    builder.addCase(ratingsAndReviews.rejected, (state, action) => {});

    // Delete Ratings and reviews
    builder.addCase(deleteRatingAndReview.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteRatingAndReview.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.isRatingDeleted = true;
      }
    });
    builder.addCase(deleteRatingAndReview.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    });
    // Search Product
    builder.addCase(searchProduct.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(searchProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload.data;
    });
    builder.addCase(searchProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload.message;
    });
  },
});

export const { setSearchTerm } = productSlice.actions;
export default productSlice.reducer;
