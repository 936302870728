import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import BASE_URL from "../../utils/baseUrl";
import axios from "axios";

export const changeStock = createAsyncThunk("changeStock", async (items) => {
  try {
    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    });
    await axiosInstance.patch(`${BASE_URL}/product/change-stock`, {
      items,
    });
  } catch (error) {
    console.log(error);
  }
});

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    data: JSON.parse(localStorage.getItem("cart")) || [],
  },
  reducers: {
    addToCart: (state, action) => {
      const product = action.payload;
      const { size } = product;
      const existingItem = state.data.find(
        (item) => item._id === product._id && item.size === size
      );

      const availableStock = product.stock[size];
      const currentCartQuantity = existingItem ? existingItem.qty : 0;

      // Check if the size is available in stock and if adding the item won't exceed the stock
      if (availableStock > currentCartQuantity) {
        if (existingItem) {
          existingItem.qty += 1;
          toast.success(
            "You have this item in your bag and we have increased the quantity by one",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            }
          );
        } else {
          state.data.push({ ...product, size, qty: 1 });
          // Show success toast
          toast.success("Item added to cart!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
        localStorage.setItem("cart", JSON.stringify(state.data));
      } else {
        toast.error("This size is not available in stock.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      }
    },
    decreaseCartItemQty: (state, action) => {
      const { id, quantity } = action.payload;
      const itemToUpdate = state.data.find((item) => item._id === id);
      if (itemToUpdate) {
        itemToUpdate.qty = quantity;
        localStorage.setItem("cart", JSON.stringify(state.data));
      }
    },
    removeFromCart: (state, action) => {
      const { id, size } = action.payload;
      state.data = state.data.filter(
        (item) => item._id !== id || item.size !== size
      );
      localStorage.setItem("cart", JSON.stringify(state.data));
    },
    changeSizeAction: (state, action) => {
      const { id, size } = action.payload;
      const itemToUpdate = state.data.find((item) => item._id === id);
      if (itemToUpdate) {
        itemToUpdate.size = size;
        localStorage.setItem("cart", JSON.stringify(state.data));
      }
    },
    changeQuantityAction: (state, action) => {
      const { id, qty } = action.payload;
      const itemToUpdate = state.data.find((item) => item._id === id);
      if (itemToUpdate) {
        itemToUpdate.qty = qty;
        localStorage.setItem("cart", JSON.stringify(state.data));
      }
    },
    emptyCart: (state, action) => {
      state.data = [];
      localStorage.removeItem("cart");
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  decreaseCartItemQty,
  changeSizeAction,
  changeQuantityAction,
  emptyCart,
} = cartSlice.actions;
export default cartSlice.reducer;
