import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";

import BASE_URL from "../../utils/baseUrl";
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from "../../utils/localStorage";

export const userLogin = createAsyncThunk(
  "userLogin",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/user/login`, body, {
        withCredentials: true, // This allows cookies to be sent
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userRegister = createAsyncThunk(
  "userRegister",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/user/register`, body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      localStorage.clear();
      const { data } = await axios.get(`${BASE_URL}/user/logout`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add address to user
export const addAddress = createAsyncThunk(
  "addAddress",
  async (address, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `${BASE_URL}/user/addAddress`,
        address
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Saved Addresses
export const fetchAddress = createAsyncThunk(
  "fetchAddress",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`${BASE_URL}/user/address`);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Saved Address
export const deleteAddress = createAsyncThunk(
  "deleteAddress",
  async ({ addressId }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`${BASE_URL}/user/address`, {
        data: { addressId },
      });
      return data.addresses;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Forgot Password
export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async ({ email }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/user/forgot-password`, {
        email,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Reset Password
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `${BASE_URL}/user/reset-password`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Update User Name
export const updateUserName = createAsyncThunk(
  "updateUserName",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `${BASE_URL}/user/update-user-name`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Request to Update User Email
export const requestToUpdateEmail = createAsyncThunk(
  "requestToUpdateEmail",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `${BASE_URL}/user/update-email-request`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update User Email
export const updateUserEmail = createAsyncThunk(
  "updateUserEmail",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `${BASE_URL}/user/update-email`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Verify Otp
export const verifyOTP = createAsyncThunk(
  "verifyOTP",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/user/authenticate-otp`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Resend OTP
export const resendOTP = createAsyncThunk(
  "resendOTP",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/user/resend-otp`, body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: getItemFromLocalStorage("user") || null,
    token: getItemFromLocalStorage("token") || null,
    isLoading: false,
    errMsg: null,
    passwordReset: false,
    passwordResetMailSent: false,
    emailSent: false,
    emailMsg: null,
    otpVerified: false,
  },
  reducers: {
    resetAll: (state) => {
      state.isLoading = false;
      state.errMsg = null;
      state.passwordReset = false;
      state.passwordResetMailSent = false;
      state.emailSent = false;
      state.emailMsg = null;
      state.otpVerified = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userRegister.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(userRegister.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emailSent = true;
      state.emailMsg = action.payload.message;
    });
    builder.addCase(userRegister.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    });

    builder.addCase(userLogin.pending, (state, action) => {
      state.user = null;
      state.token = null;
      state.isLoading = true;
      state.errMsg = null;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoading = false;
      state.errMsg = null;
      setItemToLocalStorage({ key: "user", value: action.payload.user });
      setItemToLocalStorage({ key: "token", value: action.payload.token });
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.user = null;
      state.token = null;
      state.isLoading = false;
      state.errMsg = action.payload;
    });

    // Add Address
    builder.addCase(addAddress.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      setItemToLocalStorage({ key: "user", value: state.user });
    });
    builder.addCase(addAddress.rejected, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    });

    // Fetch Saved Addresses
    builder.addCase(fetchAddress.pending, (state, action) => {});
    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      if (state.user) {
        state.user.addresses = action.payload;
        setItemToLocalStorage({ key: "user", value: state.user });
      }
    });
    builder.addCase(fetchAddress.rejected, (state, action) => {});

    // Delete Saved Address
    builder.addCase(deleteAddress.pending, (state, action) => {});
    builder.addCase(deleteAddress.fulfilled, (state, action) => {
      state.user.addresses = action.payload;
      setItemToLocalStorage({ key: "user", value: state.user });
    });
    builder.addCase(deleteAddress.rejected, (state, action) => {});

    // Forgot Password
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.passwordResetMailSent = true;
      state.errMsg = null;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.passwordResetMailSent = false;
      state.errMsg = action.payload.message;
    });

    // Reset Password
    builder.addCase(resetPassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.passwordReset = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    });

    // Update User Name
    builder.addCase(updateUserName.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserName.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      setItemToLocalStorage({ key: "user", value: action.payload });
    });
    builder.addCase(updateUserName.rejected, (state, action) => {});

    // Update User Email Request
    builder.addCase(requestToUpdateEmail.pending, (state) => {
      state.isLoading = true;
      state.errMsg = null;
    });
    builder.addCase(requestToUpdateEmail.fulfilled, (state) => {
      state.isLoading = false;
      state.emailSent = true;
    });
    builder.addCase(requestToUpdateEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload.msg;
    });

    // Update User Email
    builder.addCase(updateUserEmail.pending, (state, action) => {
      state.isLoading = true;
      state.errMsg = null;
    });
    builder.addCase(updateUserEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.data;
      setItemToLocalStorage({ key: "user", value: state.user });
    });
    builder.addCase(updateUserEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload.message;
    });

    // Verify OTP
    builder.addCase(verifyOTP.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOTP.fulfilled, (state, action) => {
      state.isLoading = false;
      state.token = action.payload.data.token;
      state.user = action.payload.data.user;
      setItemToLocalStorage({ key: "user", value: state.user });
      setItemToLocalStorage({ key: "token", value: state.token });
    });
    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload;
    });

    // Resend OTP
    builder.addCase(resendOTP.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(resendOTP.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emailMsg = action.payload.message;
      state.emailSent = true;
    });
    builder.addCase(resendOTP.rejected, (state, action) => {
      state.isLoading = false;
      state.errMsg = action.payload.message;
    });

    // Logout User
    builder.addCase(logoutUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = null;
      state.token = null;
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { resetAll } = authSlice.actions;
export default authSlice.reducer;
