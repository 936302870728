import axios from "axios";
import BASE_URL from "./baseUrl";

// Function to get the token from localStorage
const getToken = () => {
  const token = localStorage.getItem("token");
  return token ? JSON.parse(token) : null;
};

// Function to refresh the access token using the refresh token
async function refreshAccessToken() {
  try {
    const response = await axios.post(
      `${BASE_URL}/user/refresh-token`,
      {},
      {
        withCredentials: true, // Ensures that the refresh token stored in cookies is sent
      }
    );

    const newToken = response.data.accessToken;

    // Store new access token in localStorage
    localStorage.setItem("token", JSON.stringify(newToken));

    return newToken;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    return null;
  }
}

const instance = axios.create({
  baseURL: BASE_URL,
});

// Axios interceptor to set Authorization header for every request
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor to handle token expiration and retry the request
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if error is due to token expiration
    if (
      error.response.status === 401 &&
      error.response.data.error === "Token expired" &&
      !originalRequest._retry // Avoid infinite loop
    ) {
      originalRequest._retry = true; // Mark the request as retried

      // Attempt to refresh the access token
      const newToken = await refreshAccessToken();

      if (newToken) {
        // Set the new token in the Authorization header
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Retry the original request with the new token
        return instance(originalRequest);
      } else {
        localStorage.clear();
        window.alert("Session expired. Please login again.");
        window.location.href = "/auth";
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
