function setItemToLocalStorage({ key, value }) {
  localStorage.setItem(key, JSON.stringify(value));
}

function getItemFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

function removeItemFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export {
  setItemToLocalStorage,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
};
